body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.equal-height-JlocK {
  display:block;
  overflow:hidden;
  transition-property:height
}

.date{
  flex: none !important;
  width: 14.28% !important;
}

.calendar .day{
  display: flex;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.calendar .day .container{
  display: inline-block;
  position: relative;
  width: 7200px;
}

.calendar .day .timeline .current{
  display: inline-block;
  position: absolute;
  z-index: 100;

}

.calendar .day .timeline .hour{
  border-right: 1px solid gray;
  display: inline-block;
  position: absolute;
  width: 300px;

}

.calendar .day .timeline .hour:last-child{
  border-right: none;
}

.calendar .day .hourBar{
  border: 1px dashed #aaaaaa;
  display: inline-block;
  height: 2rem;
  position: absolute;
  top: 2.25rem;

}

.calendar .day .hourBar.completed{
  border: 1px dashed #00ff00;

}

.calendar .day .hourBar.conflict{
  border: 1px dashed #ff5507;

}

.calendar .day .hourBar .title{
  display: inline-block;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}

.calendar .day .hourBarClose{
  position: absolute;
  right: 0.0rem;
  text-shadow: 0px 0px 1px #000000;
  filter: drop-shadow(0px 0px 1px #000000);
  top: -0.5rem;
  z-index: 200;

}

.calendar .studentName{
  font-size: 1.5rem !important;
}

/* width */
.calendar ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.calendar ::-webkit-scrollbar-track {
  background: #fafafa;
}

/* Handle */
.calendar ::-webkit-scrollbar-thumb {
  background:#0d6efd;
  border: 1px solid black;
  border-radius: 10px;
}

/* Handle on hover */
.calendar ::-webkit-scrollbar-thumb:hover {
  background: #0d89fd;
}

.carousel-caption * {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.25em;
}

.carousel-control-next .carousel-control-next-icon {
  filter: invert(0.77) grayscale(100);
}

.carousel-control-prev .carousel-control-prev-icon {
  filter: invert(0.77) grayscale(100);
}

.carousel-indicators {
  margin-bottom: -1rem;
}

.carousel-indicators button {
  background-color: #3b3b3b !important;
  opacity: 0.25;
}

.carousel-indicators button.active {
  opacity: 0.5;
}

.multiSelect{
  width: 100%;
}

.multiSelect .dropdown-menu{
  width: 100%;
}

.multiSelect .dropdown-toggle{
  background-color: transparent !important;
  border: none !important;
  color: black !important;
  min-height: 1rem !important;
  overflow: hidden;
  padding: 0px 0.5rem !important;
  position: relative;
  text-align: left !important;
  text-overflow: ellipsis;
  width: 100% !important;
  white-space: nowrap;
}

.multiSelect .dropdown-toggle::after{
  min-height: 1rem !important;
  position: absolute;
  right: 0.5rem;
  top: 50%;
}

.note{
  position: relative;
}

.note img{
  max-width: 100% !important
}

.noteContent{
  left: 0rem;
  max-height: 90%;
  max-width: 90%;
  overflow: hidden;
  padding: 1rem;
  position: absolute;
  text-overflow: ellipsis;
  top: 0rem;
  z-index: 200;
}

.notePin{
  position: absolute;
  left: 0.5rem;
  text-shadow: 0px 0px 1px #000000;
  filter: drop-shadow(0px 0px 1px #000000);
  font-size: 1.5rem;
  top: -0.5rem;
  z-index: 200;
}

.noteRemove{
  position: absolute;
  right: 2.5rem;
  text-shadow: 0px 0px 1px #000000;
  filter: drop-shadow(0px 0px 1px #000000);
  top: -0.5rem;
  z-index: 200;
}

.studentPrize{
  box-sizing: border-box;
  display: inline-block;
  height: 13rem;
  position: relative;
  width: 100%;

}

.studentPrize .points{
  box-shadow: 0px 0px 10px #aaaaaa;
  left: -0.5rem;
  position: absolute;
  top: -0.5rem;
}

.studentPrize .description{
  height: 5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.studentPrize .title{
  height: 2rem;
  overflow-x: hidden;
  overflow-y: hidden;
}

.cursorPointer{
  cursor: pointer;
}

.home{
  font-size: 1.5rem;
  font-weight: lighter;
}

.pageIntro{
  font-size: 1.5rem;
  font-weight: lighter;
}

.pageIntro small{
  font-size: 0.7em;
}

.studentHeader{
  font-size: 1.25em;
}